"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWebviewUrl = void 0;
const getWebviewUrl = (envConfig, userDetails) => {
    var _a, _b, _c;
    if (window.location.hostname.includes("localhost")) {
        return "/web-view.html";
    }
    const userOverrideWebviewUrl = (_b = (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.preferences) === null || _a === void 0 ? void 0 : _a.formWebviewKey) !== null && _b !== void 0 ? _b : "$$na$$";
    const url = ((_c = envConfig[userOverrideWebviewUrl]) !== null && _c !== void 0 ? _c : envConfig.webview_form_url_v5);
    if (url) {
        return url.replace("https://", "/webview/");
    }
    return "/web-view.html";
};
exports.getWebviewUrl = getWebviewUrl;
